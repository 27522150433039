import axiosObservable from "axios-observable"; // Import axios-observable for observable-based requests
import axios, { AxiosRequestConfig, AxiosInstance } from "axios"; // Import standard axios for promise-based requests
import { serverUrlBase } from "../config/GlobalAppConfig";
import { NotificationType } from "../models/enums";
import { history } from "../../routes/RouterHistory";

export enum ApiCallingMethods {
    get = "GET",
    delete = "DELETE",
    head = "HEAD",
    options = "OPTIONS",
    post = "POST",
    put = "PUT",
    patch = "PATCH",
    link = "LINK",
    unlink = "UNLINK",
}

export const HTTP_STATUS_UNAUTHORIZED = 401;

class CustomAxios {
    instance: ReturnType<typeof axiosObservable.create>;
    callBack: ((params: any, type: NotificationType) => void) | null;
    private accessToken = "";

    setAccessToken(accessToken: string) {
        this.accessToken = accessToken;
    }

    constructor(axiosConfig: AxiosRequestConfig) {
        this.instance = axiosObservable.create(axiosConfig);
        this.callBack = null;

        // Request interceptor
        this.instance.interceptors.request.use(
            (config) => {
                const token = this.accessToken;
                if (token) {
                    config.headers["Authorization"] = `Bearer ${token}`;
                }
                if (axiosConfig.responseType) {
                    config.headers["Content-Type"] = axiosConfig.responseType.valueOf();
                } else {
                    config.headers["Content-Type"] = "application/json";
                }
                return config;
            },
            (error) => Promise.reject(error)
        );

        // Response interceptor
        this.instance.interceptors.response.use(
            (response) => {
                if (this.callBack) this.callBack(response, NotificationType.success);
                return response;
            },
            (error) => {
                if (this.callBack) this.callBack(error, NotificationType.error);
                const { status } = error.response;
                if (status === HTTP_STATUS_UNAUTHORIZED) {
                    if (!window.location.href.includes("/Error")) {
                        history.push("/Error", { detail: HTTP_STATUS_UNAUTHORIZED });
                    }
                }
                return Promise.reject(error.response);
            }
        );
    }

    // Observable request using axios-observable (observable-based)
    request = (options: AxiosRequestConfig, callBack: ((resp: any, type: NotificationType) => void) | null = null) => {
        this.callBack = callBack;
        return this.instance.request(options);
    };

    // Using standard axios (promise-based)
    requestAsPromise = async (options: AxiosRequestConfig): Promise<any> => {
        // Merge headers to ensure Authorization is not overwritten
        const axiosInstance: AxiosInstance = axios.create();

        const mergedOptions = {
            ...options,
            headers: {
                ...options.headers,
                Authorization: `Bearer ${this.accessToken}`,
            },
        };

        try {
            // Returns a promise from standard axios
            return await axiosInstance.request(mergedOptions);
        } catch (error) {
            console.error("Error making request:", error);

            // Provide more detailed feedback
            if (axios.isAxiosError(error)) {
                console.error("Axios error details:", error.response);
                return error.response;
            }

            // Minimal format for error (swagger)
            return {
                message: null,
                didError: true,
                errorMessage: "Network error or server unavailable",
                model: null,
            };
        }
    };
}

export default new CustomAxios({ baseURL: serverUrlBase });
