import React, { ReactNode, FC, MouseEvent } from "react";
import Modal from "@material-ui/core/Modal";
import UiBox from "../UiBox";
import CloseIcon from "@material-ui/icons/Close";

export type ModalSize = "sm" | "md" | "lg" | "xl";

export enum EUiModalReason {
    BackdropClick = "backdropClick",
    CloseIconClick = "closeIconClick",
    CancelBtnClick = "cancelBtnClick",
    TryAgainBtnClick = "tryAgainBtnClick",
    Import = "import",
    EscapeKeyDown = "escapeKeyDown",
    ApplyFilter = "applyFilter",
    ClearFilter = "clearFilter",
}

export type IPropsUiModalBase = {
    ref?: React.Ref<any>;
    open: boolean;
    /**
     * Callback fired when the component requests to be closed.
     *
     * @param {object} event The event source of the callback.
     * @param {string} reason Can be any of the defined reasons in EUiModalReason.
     */
    onClose?: (event: MouseEvent<HTMLElement> | KeyboardEvent | {}, reason: EUiModalReason) => void;
    footerButtons?: ReactNode[];
    className?: string;
};

type IPropsUiModal = IPropsUiModalBase & {
    id?: string;
    title: string;
    size?: ModalSize;
};
export const UiModal: FC<IPropsUiModal> = (props) => {
    const classes = `ui-modal__content ui-modal__content--${props.size ?? "md"}`;

    const handleClickClose = (event: any) => {
        if (props.onClose) {
            props.onClose(event, EUiModalReason.CloseIconClick);
        }
    };

    const handleModalClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
        if (props.onClose) {
            if (reason === "backdropClick" || reason === "escapeKeyDown") {
                // Map the Material-UI Modal reasons to our custom enum reasons.
                // This ensures that the 'onClose' prop receives reasons in the format our application expects
                // while still conforming to the Material-UI expected types for the 'onClose' event.
                props.onClose(event, EUiModalReason[reason as keyof typeof EUiModalReason]);
            }
        }
    };

    const renderFooter = () => {
        if (props.footerButtons && props.footerButtons.length) {
            return (
                <div className="ui-modal__footer ui-modal__footer--buttons">
                    {props.footerButtons.map((btn, index) => (
                        <div key={index}>{btn}</div>
                    ))}
                </div>
            );
        }
        return "";
    };
    return (
        <div
            onClick={(e: any) => {
                e.stopPropagation();
            }}
        >
            <Modal ref={props.ref} open={props.open} onClose={handleModalClose} className={props.className}>
                <div id={props.id} className="ui-modal">
                    <div className={classes}>
                        <UiBox
                            title={props.title}
                            actions={
                                <button onClick={handleClickClose} className="ui-modal__close">
                                    <CloseIcon fontSize="large" />
                                </button>
                            }
                        >
                            <>
                                {props.children}
                                {renderFooter()}
                            </>
                        </UiBox>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
