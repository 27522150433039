import React, { useMemo, useState } from "react"
import { Container, Grid } from "@material-ui/core"
import TextTruncate from 'react-text-truncate';
import {UiStatus} from "../ui/UiStatus";
import {UiTags} from "../ui/UiTags";
import NumberFormat from "react-number-format";
import { INewEvent } from "../../../event/interface";
import { StatusType } from "../../models/enums";
import { GetEventStatusLabel } from "../../helpers/HelpersBusiness";
import { truncateMaxCharNb, truncateText } from "../../config/GlobalAppConfig";

interface Props {
    event: INewEvent
}

export const UiEventRecap = (props: Props) => {
    let uid = "-";
    let date = "-";
    let day = "-";
    const [statusTag, setStatusTag] = useState<StatusType>('draft');
    const [statusLabel, setStatusLabel] = useState('');

    const formatFullName = (lastName?: string, firstName?: string) => {
        if (!lastName || !firstName) return "";
        return `${lastName.toUpperCase()} ${firstName}`;
    };

    const riskLevelValue = useMemo(()=>{
        // TODO GET RISK LEVEL VALUE FROM context (back need to provide unformatted value instead of "Risk Level 0")
        /*if(props.event.riskLevel && riskLevel.items.length){
           return riskLevel.items.find(i=>i.id === props.event.riskLevel).value - 1
        }*/
        return props.event && Number(props.event.riskLevel) - 1;
    }, [props.event])
    if(props.event){
        let year = undefined
        if(props.event.fromDate && props.event.toDate){
            date = props.event.fromDate + " to " + props.event.toDate 
            
            let fromDate : any = new Date(props.event.fromDate)
            let toDate : any = new Date(props.event.toDate)
            day = ((Math.abs(toDate - fromDate) / 86400000) + 1).toString();
            year = fromDate.getFullYear()

        } else if(props.event.fromDate && !props.event.toDate ){
            date = props.event.fromDate
            day = "1"
            year = new Date(props.event.fromDate).getFullYear()
        } else if(!props.event.fromDate && props.event.toDate){
            date = props.event.toDate
            day = "1"
            year = new Date(props.event.toDate).getFullYear()
        }

        let uid_year = year ? year.toString().substring(2) : "XX"
        let uid_maison = props.event.leadbrandName ? props.event.leadbrandName : "XX"
        let uid_country = props.event.selectedCountry ? props.event.selectedCountry.itemContent : "XX"
        uid = uid_year + "_" + uid_maison + "_" + uid_country + "_" + props.event.id
    }

    React.useEffect(() => {
        if(props.event?.eventsStatus && props.event?.eventsStatus.length) {
            const label = GetEventStatusLabel(props.event.eventsStatus) as StatusType;
            setStatusLabel(label);
            const tag = label?.toLowerCase().replace(/ /g, '').replace('.', '') as StatusType
            setStatusTag(tag);
        }
        else {
            setStatusLabel('Draft');
        }
    }, [props.event, setStatusTag])

    return(
        <div className="event-recap">
            <Container className="event-recap__content" maxWidth="xl">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                        <span className="event-recap__title">
                            <TextTruncate
                                line={2}
                                truncateText={truncateText}
                                maxCalculateTimes={truncateMaxCharNb}
                                text={(props.event && props.event.eventName) ? props.event.eventName : ''}
                            />
                        </span>
                        <div className="event-recap__tags">
                            {props.event && props.event.leadbrandName && <UiTags label={props.event.leadbrandName} uiStyle="tags primary"/>}
                        </div>
                        <div className="event-recap__tags">
                            {props.event &&  props.event.riskTags.isHeritage && <UiTags label="Heritage" />}
                            {props.event &&  props.event.riskTags.isStrategic && <UiTags label="Open displays" />}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} className="event-recap__infos">
                        <p><span>Date:</span> {date}</p>
                        <p>
                            <span>Location:</span>
                            {props.event?.locationName}
                            {props.event?.locationName && props.event?.selectedCountry?.itemContent && ', '}
                            {props.event?.selectedCountry?.itemContent}
                        </p>
                        <p>
                            <span>Person accountable: </span>
                            {formatFullName(props.event.accountableForEventLastName, props.event.accountableForEventFirstName)}
                        </p>
                        <p>
                            <span>Responsible of the security: </span>
                            {formatFullName(props.event.responsibleOfSecurityLastName, props.event.responsibleOfSecurityFirstName)}
                        </p>
                    </Grid>
                    <Grid item xs={12} md={5} className="event-recap__numbers">
                            <div className="p-40"><p>Status</p><UiStatus label={statusLabel} name={statusTag}/></div>
                            <div><p>Risk Level</p><span>{props.event && <>{riskLevelValue}</>}</span></div>
                            <div><p>Day</p><span id="daysRemaining">{day}</span></div>
                            <div>
                                <p>Estimated amount</p>
                                <span id="estimatedAmount"><NumberFormat value={props.event && props.event.estimatedTotalValue} displayType={'text'} thousandSeparator="'"  prefix={'€'} /></span>
                            </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default UiEventRecap;