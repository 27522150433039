import React, { useState, DragEvent, ChangeEvent, useRef, useEffect, ReactNode } from "react";
import "./DragAndDrop.scss";
import CachedIcon from "@material-ui/icons/Cached";
import { Button } from "@material-ui/core";
import { toNumber } from "lodash";

const MAX_FILE_SIZE = (): number => {
    const val = toNumber(process.env.REACT_APP_UPLOAD_MAX_FILE_SIZE);
    return isNaN(val) || val == null ? 3 : val;
};

interface IDragAndDropProps {
    onFileDrop: (file: File) => void;
    accept: string;
    children?: ReactNode;
    maxFileSize?: number;
    externalLoading?: boolean;
    externalError?: string;
    onLoadingChange?: (loading: boolean) => void;
    onErrorChange?: (error: string | undefined) => void;
    inputRef?: React.RefObject<HTMLInputElement>;
}

const DragAndDrop: React.FC<IDragAndDropProps> = ({
    onFileDrop,
    accept,
    children,
    maxFileSize = MAX_FILE_SIZE(),
    externalLoading = false,
    externalError = null,
    onLoadingChange,
    onErrorChange,
    inputRef,
}) => {
    const [isDragging, setIsDragging] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const isMountedRef = useRef(true);

    useEffect(() => {
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    const internalFileInputRef = useRef<HTMLInputElement>(null);
    const fileInputRef = inputRef || internalFileInputRef;

    const isFileAccessible = async (file: File): Promise<boolean> => {
        try {
            await file.arrayBuffer();
            return true;
        } catch {
            return false;
        }
    };

    const handleDragEnter = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(true);
    };

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);
    };

    const handleDrop = async (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);

        const file = event.dataTransfer.files[0];
        if (file && validateFile(file)) {
            clearError();
            if (!(await isFileAccessible(file))) {
                handleError("This file cannot be imported while it's open in Excel. Please close Excel and try again.");
                return;
            }
            startLoading();
            try {
                await onFileDrop(file);
            } catch {
                handleError("An error occurred during import. Please try again.");
            } finally {
                if (isMountedRef.current) stopLoading();
            }
        } else {
            handleError("Invalid file format. Please upload a valid file.");
        }
    };

    const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && validateFile(file)) {
            clearError();
            if (!(await isFileAccessible(file))) {
                handleError("This file cannot be imported while it's open in Excel. Please close Excel and try again.");
                return;
            }
            startLoading();
            try {
                await onFileDrop(file);
            } catch {
                handleError("An error occurred during import. Please try again.");
            } finally {
                if (isMountedRef.current) stopLoading();
            }
        } else {
            handleError("Invalid file format. Please upload a valid file.");
        }
    };

    const handleClick = () => {
        fileInputRef.current?.click();
    };

    const validateFile = (file: File): boolean => {
        const validExtensions = accept.split(",").map((ext) => ext.trim());
        const fileExtension = file.name.split(".").pop()?.toLowerCase();
        const isValidSize = file.size <= maxFileSize * 1024 * 1024;
        return fileExtension ? validExtensions.includes(`.${fileExtension}`) && isValidSize : false;
    };

    const startLoading = () => {
        setIsLoading(true);
        if (onLoadingChange) onLoadingChange(true);
    };

    const stopLoading = () => {
        setIsLoading(false);
        if (onLoadingChange) onLoadingChange(false);
    };

    const handleError = (message: string) => {
        setError(message);
        if (onErrorChange) onErrorChange(message);
    };

    const clearError = () => {
        setError(null);
        if (onErrorChange) onErrorChange(undefined);
    };

    return (
        <div
            className={`drag-and-drop ${isDragging ? "drag-and-drop--dragging" : ""}`}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            <input
                accept={accept}
                type="file"
                onChange={handleFileUpload}
                style={{ display: "none" }}
                ref={fileInputRef}
            />
            {isLoading || externalLoading ? (
                <div className="drag-and-drop__loading">
                    <CachedIcon className="drag-and-drop__loading-icon" />
                    <p>Loading...</p>
                </div>
            ) : (
                <>
                    {children ? (
                        children
                    ) : (
                        <section>
                            <p>Drag Excel file here</p>
                            <p>OR</p>
                            <Button variant="text" color="primary" component="span" onClick={handleClick}>
                                Browse files...
                            </Button>
                        </section>
                    )}
                    {error || externalError ? <p className="drag-and-drop__error">{error || externalError}</p> : null}
                </>
            )}
        </div>
    );
};

export default DragAndDrop;
