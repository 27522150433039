import {
    TImportEventResponse,
    TAddDuplicateEventsResponse,
    EApiResponseCustomStatus,
} from "../../../shared/services/massImportLVL1/massImportService";
import { EAlertSeverity, IStatusAlertProps } from "./StatusAlert";

export function getAlertProps(
    response: TImportEventResponse | TAddDuplicateEventsResponse | null
): IStatusAlertProps | null {
    if (!response) return null;

    const { message, status, customStatus } = response;

    const severity = (() => {
        switch (status) {
            case "success":
                return EAlertSeverity.Success;
            case "error":
                return EAlertSeverity.Error;
            case "duplicate":
                return EAlertSeverity.Warning;
            default:
                return EAlertSeverity.Error;
        }
    })();

    const title = (() => {
        switch (customStatus) {
            case EApiResponseCustomStatus.FileImportedWithSuccess:
                return "File successfully uploaded";
            case EApiResponseCustomStatus.DuplicateEventsAddedSuccessfully:
                return "Import successful";
            case EApiResponseCustomStatus.ErrorsInFile:
                return "Errors detected in file";
            case EApiResponseCustomStatus.DuplicateData:
                return "Duplicate entries found";
            case EApiResponseCustomStatus.InvalidFileFormat:
                return "Invalid file format";
            default:
                return "An unknown status occurred";
        }
    })();

    return {
        severity,
        title,
        message: message || "An unknown error occurred.",
    };
}
