import React, { useState, useEffect, MouseEvent } from "react";
import { UiModal, IPropsUiModalBase, EUiModalReason } from "../../../shared/components/ui/UiModal/UiModal";
import ImportHistoryList from "./ImportHistoryList";
import {
    TImportedFilesResponse,
    TImportedFile,
    TRollbackImportedFileResponse,
} from "../../../shared/services/massImportLVL1/massImportService";

interface IModalHistoryImportedLVL1Props extends IPropsUiModalBase {
    fetchHistory: () => Promise<TImportedFilesResponse>;
    onRollback: (importedFile: TImportedFile) => Promise<TRollbackImportedFileResponse>;
}

const ModalHistoryImportedLVL1: React.FC<IModalHistoryImportedLVL1Props> = ({
    fetchHistory,
    onRollback,
    onClose,
    open,
    ...rest
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [history, setHistory] = useState<TImportedFile[]>([]);

    useEffect(() => {
        if (open) {
            loadHistory();
        }
    }, [open]);

    const loadHistory = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetchHistory();
            setHistory(response.model.importedFiles);
        } catch (err) {
            setError("Failed to load history. Please try again.");
            console.error("Error fetching history:", err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = (event: {} | MouseEvent<HTMLElement> | KeyboardEvent, reason: EUiModalReason) => {
        onClose?.(event, reason);
        if (
            reason === EUiModalReason.CloseIconClick ||
            reason === EUiModalReason.BackdropClick ||
            reason === EUiModalReason.EscapeKeyDown
        ) {
            setHistory([]);
            setError(null);
            // relad the page to show the updated data
            window.location.reload();
        }
    };

    return (
        <UiModal
            title="Import History"
            open={open}
            onClose={handleClose}
            className="modal-import-history-lvl1"
            {...rest}
        >
            <section>
                <p>
                    You can rollback imported events. All events from the import will be deleted, all modifications done
                    on the events will be lost.
                </p>
            </section>
            <ImportHistoryList
                history={history}
                onRollback={onRollback}
                onRefresh={loadHistory}
                isLoading={isLoading}
                error={error}
            />
        </UiModal>
    );
};

export default ModalHistoryImportedLVL1;
