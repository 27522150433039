import React, { Dispatch, MouseEvent, SetStateAction } from "react";
import { Button } from "@material-ui/core";
import { EUiModalReason } from "../../../shared/components/ui/UiModal/UiModal";
import { EAlertStatus, TDuplicateEvent } from "../../../shared/services/massImportLVL1/massImportService";
import { TImportState } from "./ModalImportLVL1";

export interface IFooterModalImportLVL1 {
    statusResponse: EAlertStatus | null;
    onClose: (event: MouseEvent<HTMLElement> | KeyboardEvent | {}, reason: EUiModalReason) => void;
    setImportResponse: Dispatch<SetStateAction<TImportState>>;
    selectedDuplicates: TDuplicateEvent[];
    onDuplicateSubmit: () => Promise<void>;
    isLoading: boolean;
    importState: TImportState;
    hasImportableEvents: boolean;
}

const FooterModalImportLVL1: React.FC<IFooterModalImportLVL1> = ({
    statusResponse,
    onClose,
    setImportResponse,
    selectedDuplicates,
    onDuplicateSubmit,
    isLoading,
    importState,
    hasImportableEvents,
}) => {
    const handleCloseClick = (event: MouseEvent<HTMLButtonElement>) => {
        return onClose(event, EUiModalReason.CloseIconClick);
    };

    const handleCancelClick = (event: MouseEvent<HTMLButtonElement>) => onClose(event, EUiModalReason.CancelBtnClick);

    const handleImportClick = () => {
        setImportResponse(null);
    };

    const importButtonLabel =
        statusResponse === EAlertStatus.Success && importState?.type === "duplicate"
            ? "Import Another"
            : "Import New File";

    switch (statusResponse) {
        case EAlertStatus.Success:
            return (
                <>
                    <Button key="close" onClick={handleCloseClick} variant="outlined" color="primary">
                        Close
                    </Button>
                    <Button key="import-new" variant="contained" onClick={handleImportClick} color="primary">
                        {importButtonLabel}
                    </Button>
                </>
            );

        case EAlertStatus.Error:
            return (
                <>
                    <Button key="try-again" variant="contained" onClick={handleImportClick} color="primary">
                        Try Again
                    </Button>
                    <Button key="cancel" onClick={handleCancelClick} variant="outlined" color="primary">
                        Cancel
                    </Button>
                </>
            );

        case EAlertStatus.Duplicate:
            const hasImportableContent = hasImportableEvents || selectedDuplicates.length > 0;

            return (
                <>
                    <Button key="cancel" onClick={handleCancelClick} variant="outlined" color="primary">
                        Cancel
                    </Button>
                    <Button
                        key="import"
                        onClick={onDuplicateSubmit}
                        variant="contained"
                        color="primary"
                        disabled={!hasImportableContent || isLoading}
                    >
                        {isLoading ? "Importing..." : `Import`}
                    </Button>
                </>
            );

        default:
            return (
                <>
                    <Button key="cancel" onClick={handleCancelClick} variant="outlined" color="primary">
                        Cancel
                    </Button>
                </>
            );
    }
};

export default FooterModalImportLVL1;
